import {FC} from "react";
import {SelectTicketCard} from "../SelectTicketDurationPage/select-ticket.style";
import {Button, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../utils/ioc";
import {CoreStore} from "../../stores/core.store";

export const PlayersTicketConfig = [
  1, 2, 3, 4
]


export const SelectedPlayersAmountPage: FC = observer(() => {
  const navigate = useNavigate();
  const {changeStep, step, resetSetup, setPlayers} = useStore(CoreStore);

  const handleChoosePlayer = (amount: number) => {
    setPlayers(amount);
    changeStep('selectDuration');
  }

  return (
    <SelectTicketCard title={'Please select players amount'} size={'default'}>
      {PlayersTicketConfig.map((v) => (
        <Button key={'player-' + v} onClick={() => handleChoosePlayer(v)}>
          {v} {v > 1 ? 'Players': 'Player' }
        </Button>
      ))}
      <Row style={{width: '100%'}}>
        <Button onClick={() => {
          navigate('/dashboard');
          resetSetup();
        }} style={{marginTop: '16px'}}>
          Back to main menu
        </Button>
      </Row>

    </SelectTicketCard>
  )
})