import {FC, useEffect, useMemo, useRef, useState} from "react";
import {
    AnimatedBackground,
    GameCard,
    GamesListStandWrapper,
    GamesListWrapper,
    PageWrapper,
    ScrollableArea, StandGameCard,
    StandScrollableArea
} from "./style";
import Swiper from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import {Button, Card, Col, Modal, Row, Table} from "antd";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../utils/ioc";
import {useNavigate} from "react-router-dom";
import {CoreStore} from "../../ticket/stores/core.store";
import {Game} from "../../../api/getGamesList";
import {Image} from 'antd';

const BackgroundVideo = 'https://api.jinxvr.com/static/stand.mp4'

export const StandPage: FC = observer(() => {
    const navigate = useNavigate();
    const {games, fetchGames} = useStore(CoreStore);
    const [isModalOpen, toggleModal] = useState(false);
    const [currentGame, setCurrentGame] = useState<Game>({
        title: '',
        youtube_url: '',
        wallpaper: '',
        uuid: '',
        description: '',
        exe_name: '',
        steam_game_id: '',
        max_player_number: 0,
        id: 0,
        min_player_number: 0,
    });

    useEffect(() => {
        setInterval(() => fetchGames(), 10 * 1000)
        const videoTag = document.getElementById('trailer');
        const bgTag = document.getElementById('bg-video');

        if (!videoTag || !bgTag) {
            return
        }


        setTimeout(() => {
            // @ts-ignore
            videoTag.play();
            // @ts-ignore
            bgTag.play();
        }, 3000);
    }, [])

    const handleOnClick = (game: Game) => {
        setCurrentGame(game);
        toggleModal(true);
    }

    const modalClose = () => {
        toggleModal(false);
        document.getElementById('youtube_iframe')!.setAttribute('src', '');
    }



    return (
      <GamesListStandWrapper>
          <AnimatedBackground src={BackgroundVideo} muted={true} autoPlay={true} loop={true} id={'bg-video'} controls={false} style={{height: '100%', marginRight: '-540px'}}/>
          <Row >
              <StandScrollableArea>
                  {games.map((game, index) => (
                    <StandGameCard
                      title={game.title}
                      size={'default'}
                      style={{width: '100%', marginBottom: '16px'}}
                      key={game.uuid}
                      onClick={() => handleOnClick(game)}
                    >
                        <Image src={game.wallpaper} style={{height: '150px'}} preview={false}/>
                    </StandGameCard>
                  ))}

              </StandScrollableArea>
              <div style={{display: 'flex', width: '100%', justifyContent: 'center', zIndex: 1}}>
                  <video
                    src={'https://api.jinxvr.com/static/trailer.mp4'}
                    width="100%"
                    height="620"
                    controls
                    id="trailer"
                    muted={true}
                    loop={true}
                  >
                  </video>
              </div>


          </Row>
          <Modal
            title={currentGame.title}
            centered
            open={isModalOpen}
            okText={'Close'}
            cancelButtonProps={{style: {display: 'none'}}}
            okType={'ghost'}
            onOk={modalClose}
            onCancel={modalClose}
            width={1000}
          >
              <iframe id="youtube_iframe"
                      width="100%" height="500"
                      src={currentGame.youtube_url}
                      allowFullScreen={true}>
              </iframe>
          </Modal>

      </GamesListStandWrapper>

    )
})