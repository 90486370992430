import {FC, useState} from "react";
import {Button, Input, Row} from "antd";
import {SelectTicketCard} from "../../../ticket/pages/SelectTicketDurationPage/select-ticket.style";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../utils/ioc";
import {AuthStore} from "../../../ticket/stores/auth.store";
import {useNavigate} from "react-router-dom";
import {AuthCard} from "./style";

export const AuthPage: FC = observer(() => {
  const {login} = useStore(AuthStore);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const attemptToLogin = async () => {
    setIsLoading(true)
    try {
      await login(username, password);
    } catch (e) {
      setIsLoading(false)
      setError(true);
      return
    }
    setIsLoading(true)
    navigate('/dashboard')
  }

  return (
    <AuthCard title={'Please sign in'} size={'default'} >
      <Input placeholder="Login" value={username} onChange={(e) => {
        setUsername(e.target.value)
        setError(false);
      }} style={{marginBottom: '16px;'}} size={'large'}/>
      <Input placeholder="Password" value={password} type='password' onChange={(e) => {
        setPassword(e.target.value)
        setError(false);
      }} size={'large'} style={{marginBottom: '16px;'}}/>
      {error && <p style={{color: 'red'}}>Incorrect login or password</p>}
      <Button onClick={attemptToLogin} disabled={isLoading} style={{width: '100%'}} size={'large'}>
        Sign in
      </Button>
    </AuthCard>
  )
})