import "antd/dist/antd.css"
import React, {FC, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, Navigate, useNavigate} from "react-router-dom";
import {TicketRouter} from './ticket/ticket.router';
import { Main } from './main/Main';
import {Button, Layout} from 'antd';
import {GamesRouter} from "./games/games.router";
import {MonitoringRouter} from "./monitoring/monitoring.router";
import {CustomLayout, Wrapper} from "./app.style";
import {AuthPage} from "./auth/pages/AuthPage/AuthPage";
import {observer} from "mobx-react-lite";
import {AuthStore} from "./ticket/stores/auth.store";
import {useStore} from "../utils/ioc";
import {GamesPage} from "./games/pages/games.page";
import {StandPage} from "./games/pages/stand.page";
import {TrailerPage} from "./external/TrailerPage";

export const DashboardRouter: FC = observer(() => {
  const {isLoggedIn, checkIsLoggedIn, login, isLoading} = useStore(AuthStore);
  const navigate = useNavigate();
  useEffect(() => {
    checkIsLoggedIn().then(() => {
      navigate('/dashboard')
    }).catch(() => {
      navigate('/auth')
    })
  }, [])
  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Wrapper>
      <div style={{position: 'fixed', top: 0, right: 0}}>
        {localStorage.getItem('token') && (
          <Button onClick={() => {
            localStorage.removeItem('token')
            window.location.reload()
          }}>Logout</Button>
        )}
      </div>
      <Routes>
        <Route path={'/'} element={<Main/>} />
        <Route path={'/tickets/*'} element={<TicketRouter/>} />
        <Route path={'/games/*'} element={<GamesRouter/>}/>
        <Route path={'/monitoring/*'} element={<MonitoringRouter/>}/>
        <Route path={"*"} element={<Navigate to={'/'}/>} />
      </Routes>
    </Wrapper>

  )
})

export const AuthRouter: FC = observer(() => {
  const {isLoggedIn, checkIsLoggedIn, login, isLoading} = useStore(AuthStore);
  const navigate = useNavigate();
  useEffect(() => {
    checkIsLoggedIn().then(() => {
      navigate('/dashboard')
    }).catch(() => {
      navigate('/auth')
    })
  }, [])
  if (isLoading) {
    return <div>Loading...</div>
  }
  return (
    <Routes>
      <Route path={'/'} element={<AuthPage/>}/>
    </Routes>
  )
})

export const App: FC = () => {
  return (
    <CustomLayout className={'ant-layout'} >

      <BrowserRouter>
        <Routes>
          <Route path={'/dashboard/*'} element={<DashboardRouter/>} />
          <Route path={'/auth/*'} element={<AuthRouter/>} />
          <Route path={"/external/games"} element={<GamesPage/>}/>
          <Route path={"/external/stand"} element={<StandPage/>}/>
          <Route path={'/external/trailer'} element={<TrailerPage/>}/>
          <Route path={'*'} element={<Navigate to={'/dashboard'}/>}/>
        </Routes>

      </BrowserRouter>
    </CustomLayout>

  )
}