import {observer} from "mobx-react-lite";
import {Button, Card, Col, Modal, notification, Row, Table, Input} from "antd";
import {useStore} from "../../../utils/ioc";
import {MonitoringStore} from "../stores/monitoring.store";
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import differenceInMinutes from "date-fns/differenceInMinutes";
import {MonitoringCard, TableCard} from "./monitoring.style";
import {CoreStore} from "../../ticket/stores/core.store";


export const MonitoringPage = observer(() => {
  const {
    startMonitoringInterval,
    computers,
    tickets,
    stopGame, fetchComputers,
    stopMonitoringInterval,
    addTime
  } = useStore(MonitoringStore);
  const [isClosingModal, setIsClosingModal] = useState(false);
  const [selectedComputerUuid, setSelectedComputerUuid] = useState<string | null>(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const navigate = useNavigate();
  const {assignUserToComputer, restartPC, deleteSuperHot} = useStore(CoreStore);

  useEffect(() => {
    startMonitoringInterval();
  }, [])

  const handleOptionClick = (computerUuid: string) => {
    setIsOptionsOpen(true);
    setSelectedComputerUuid(computerUuid);
  }

  const renderAction = (uuid: string, state: string) => {
    return <Button onClick={() => {
      handleOptionClick(uuid)
    }}>Options</Button>
  }

  const timeLeft = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}m ${secondsLeft}s`;
  }

  const computersData = useMemo(() => {
    return computers.filter(comp => comp.active).map((computer) => {
      return {
        id: computer.id,
        title: computer.pc_name,
        state: computer.state,
        ticket: computer.ticket,
        game: computer.game_name ? computer.game_name : 'none',
        time: computer.state === 'busy' ? timeLeft(computer.time) : '',
        actions: renderAction(computer.uuid, computer.state)
      }
    });
  }, [computers])

  const computersColumns = [
   {
      id: 'title',
      title: 'title',
      dataIndex: 'title',
    }, {
      id: 'state',
      title: 'state',
      dataIndex: 'state',
    },
    {
      id: 'game',
      title: 'game',
      dataIndex: 'game',
    },
    {
      id: 'time',
      title: 'Left time',
      dataIndex: 'time',
    },
    {
      id: 'actions',
      title: 'actions',
      dataIndex: 'actions',
    },
  ]

  const ticketsColumns = [
    {
      id: 'id',
      title: 'id',
      dataIndex: 'id',
    }, {
      id: 'expiresIn',
      title: 'Expires in',
      dataIndex: 'expiresIn',
    }, {
      id: 'action',
      title: 'action',
      dataIndex: 'action',
    }
  ];

  const selectedComputer = useMemo(() => {
    return computers.find(comp => comp.uuid === selectedComputerUuid);
  }, [selectedComputerUuid]);

  const onAcceptCloseGame = () => {
    if (!selectedComputer) {
      return
    }
    stopGame(selectedComputer.uuid).then(() => {
      fetchComputers()
      setIsClosingModal(false);
      setSelectedComputerUuid(null);
    })
  }

  const onDeclineCloseGame = () => {
    setIsClosingModal(false);
    setSelectedComputerUuid(null);
  }

  const [time, setTime] = useState(0);
  const handleAddTime = (compId: string, minutes: number) => {
    addTime(compId, minutes).then(() => {
      setIsOptionsOpen(false);
    })
  }

  const ticketsData = useMemo(() => {
    return tickets.filter(ticket => (!ticket.computer && !ticket.expired)).map((ticket) => {
      return {
        key: ticket.id,
        id: ticket.id,
        expiresIn: ticket.finishDate ? `${differenceInMinutes(new Date(), new Date(ticket.finishDate))} minutes`: `${ticket.expiresInMinutes} minutes`,
        action: <Button onClick={() => {
          assignUserToComputer(ticket.uuid);
          navigate('/dashboard/tickets/setup');
        }}>Assign Ticket</Button>
      }
    })
  }, [tickets]);

  return (
    <MonitoringCard title={'System monitoring'} size={'default'}>
      <Row>
        <TableCard title={'Computers'} size={'default'}>
          <Table dataSource={computersData} columns={computersColumns}/>
        </TableCard>
        <TableCard title={'Unused tickets'} size={'default'}>
          <Table dataSource={ticketsData} columns={ticketsColumns}/>
        </TableCard>
      </Row>
      <Button onClick={() => navigate('/dashboard')} style={{marginTop: '16px'}}>
        Back to main menu
      </Button>
      <Modal
        title="Options"
        open={(isOptionsOpen && !!selectedComputerUuid)}
        onOk={() => setIsOptionsOpen(false)}
        okText={'Close'}
        onCancel={() => setIsOptionsOpen(false)}
        cancelButtonProps={{style: {display: 'none'}}}
      >
        {selectedComputer && selectedComputer.state !== 'busy' && (
          <>
            <Button onClick={() => {
              if (!selectedComputerUuid) {
                return
              }
              restartPC(selectedComputerUuid).then(() => {
                notification.success({
                  message: 'Computer was rebooted'
                })
              }).catch(() => {
                notification.error({
                  message: 'Computer was not rebooted, check console'
                })
              }).finally(() => {
                setIsOptionsOpen(false);
                setSelectedComputerUuid(null);
              })
            }} style={{marginRight: '16px'}} >
              Restart PC
            </Button>
            <Button onClick={() => {
              if (!selectedComputerUuid) {
                return
              }
              deleteSuperHot(selectedComputerUuid).then(() => {
                notification.success({
                  message: 'Hot files deleted'
                })
              }).catch(() => {
                notification.error({
                  message: 'Hot files was not deleted'
                })
              }).finally(() => {
                setIsOptionsOpen(false);
                setSelectedComputerUuid(null);
              })
            }} style={{marginRight: '16px'}}>
              Delete hot files
            </Button>
          </>

          )}
        {selectedComputer && selectedComputer.state === 'busy' && (
          <>
            <Button onClick={() => {
              onAcceptCloseGame();
              setIsOptionsOpen(true)
            }} >Stop game</Button>
            <Input.Group compact style={{marginTop: '16px'}}>
              <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="" placeholder={'Add minutes'} onChange={(v) => setTime(Number(v.target.value)) }/>
              <Button type="primary" onClick={() => handleAddTime(selectedComputer.uuid, time)}>Add time</Button>
            </Input.Group>
          </>

        )}

      </Modal>
    </MonitoringCard>
  )
});