import {FC, useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../utils/ioc";
import {CoreStore, SetupStep} from "../../stores/core.store";
import {SelectedPlayersAmountPage} from "../SelectPlayersAmountPage/SelectedPlayersAmountPage";
import {SelectTicketDurationPage} from "../SelectTicketDurationPage/SelectTicketDurationPage";
import {SelectGamePage} from "../SelectGamePage/SelectGamePage";
import {TicketAssignToComputer} from "../TicketAssignToComputer/TicketAssignToComputer";

export const SetupManager: FC = observer(() => {
  const {step} = useStore(CoreStore);

  const renderStep = () => {
    const variants: Record<SetupStep, any> = {
      'selectPlayers': <SelectedPlayersAmountPage/>,
      'selectDuration': <SelectTicketDurationPage/>,
      'selectGame': <SelectGamePage/>,
      'selectComputers': <TicketAssignToComputer/>,
    }
    return variants[step]
  }
  
  return renderStep();
})