import {AxiosResponse} from "axios";
import {api} from "./api";

export type CreateTicketRequest = {
  expiresInMinutes: number
}


export interface TicketModel {
  computer?: any;
  expiresInMinutes: number;
  id: number;
  expired?: boolean;
  startDate: string;
  finishDate?: string;
  uuid: string;
}

export type CreateTicketModel = {
  error: boolean;
  payload: TicketModel;
}

export type CreateTicketResponse = AxiosResponse<CreateTicketModel>;

export const createTicket = (data: CreateTicketRequest) => {
  return api.post<CreateTicketModel>("/createTicket", data);
}