import {Card} from "antd";
import styled from "styled-components";

export const MonitoringCard = styled(Card)`
  max-width: 1440px;
  width: 100%;
  
  & .ant-card-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 600px;
    overflow-y: scroll;
    
    .ant-row {
      justify-content: space-between;
    }
  }
`;

export const TableCard = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  
  @media(min-width: 1025px) {
    width: 49%
  }
  * {
 
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;