import {Layout} from "antd";
import styled from "styled-components";

export const CustomLayout = styled(Layout)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Wrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;