import {observer} from "mobx-react-lite";
import {Button, Card, Modal, notification, Select, Table} from "antd";
import {useStore} from "../../../../utils/ioc";
import {CoreStore} from "../../stores/core.store";
import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {AssignCard} from "./assign.style";

export const TicketAssignToComputer = observer(() => {
  const {computers, changeStep, fetchComputers, startGames, players, resetSetup, selectedTicketUuid, startGame, selectedGame} = useStore(CoreStore);
  const [computersUuids, setComputersUuids] = useState<string[]>([]);
  const navigate = useNavigate();

  const columns = [ {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: 'state',
    dataIndex: 'state',
    key: 'state',
  }, {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    }]

  const dataSources = computers.filter(item => item.state === 'idle').map((computer) => {
    return {
      key: computer.id,
      name: computer.pc_name,
      state: computer.state,
      action: !computersUuids.includes(computer.uuid) ? <Button onClick={() => {
        setComputersUuids([...computersUuids, computer.uuid])
      }}>Select computer</Button> : <Button onClick={() => {
        setComputersUuids(computersUuids.filter(item => item !== computer.uuid))
      }}>Unselect computer</Button>
    }
  });

  useEffect(() => {
    fetchComputers();
  }, [])

  const handleGameStart = () => {
    if (selectedTicketUuid && selectedGame) {
      startGame([{
        compUuid: computersUuids[0],
        gameUuid: selectedGame.uuid,
        ticketUuid: selectedTicketUuid,
        steam_game_id: selectedGame.steam_game_id
      }]).then(() => {

        notification.success({
          message: 'Game started successfully'
        })
        navigate('/dashboard/monitoring');
        resetSetup();
      }).catch((e) => {
        notification.error({
          message: 'Game start failed'
        })
        console.error('Game start failed', e)
      })
      return
    }

    startGames(computersUuids).then(() => {

      notification.success({
        message: 'Game started successfully'
      })
      navigate('/dashboard/monitoring');
      resetSetup();
    }).catch((e) => {
      notification.error({
        message: 'Game start failed'
      })
      console.error('Game start failed', e)
    })
  }

  return (
    <AssignCard title={'Please select computer for game'} size={'default'}>
      <Table dataSource={dataSources} columns={columns}/>
      <Button onClick={() => changeStep('selectGame')} style={{marginTop: '16px'}}>
        Back to games
      </Button>
      {computersUuids.length === players && (
        <Button onClick={handleGameStart} style={{marginTop: '16px'}}>
          Start game
        </Button>
      )}
    </AssignCard>
  )
})