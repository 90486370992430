import styled from "styled-components";
import {Card} from "antd";

export const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;
export const StandScrollableArea = styled.div`
  max-height: 1300px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const ScrollableArea = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const AnimatedBackground = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 200%;
  min-height: 100%;
  
`;
export const GamesListStandWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  height: 1920px;

  
`;

export const GamesListWrapper = styled(Card)`
  width: 100%;
  max-width: 1200px;
`;

export const GameCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  @media(min-width: 800px) {
    max-width: 50%;
  }
`;

export const StandGameCard = styled(Card)`
  width: 100%;
  background: rgba(0,0,0,0.4);
  max-width: 600px;
  
  @media(min-width: 800px) {
    max-width: 30%;
  }
`;