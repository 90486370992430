import {FC, useEffect, useState} from "react";
import { SelectTicketCard } from "../SelectTicketDurationPage/select-ticket.style";
import {Button, Row, Select} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {GameModel} from "../../../../api/getGameInfo";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../utils/ioc";
import {CoreStore} from "../../stores/core.store";
import {SelectGameStyle} from "./select-game.style";

export const SelectGamePage: FC = observer(() => {
  const {step, changeStep, setGame, selectedGame, games, fetchGames} = useStore(CoreStore);

  useEffect(() => {
    fetchGames();
  }, [])

  const handleChangeGame = (gameUuid: string) => {
    const game = games.find(i => i.uuid === gameUuid);
    if (!game) {
      console.log('Game not found', gameUuid);
      return
    }

    setGame(game);

  }

  return (
    <SelectGameStyle title={'Please select game'} size={'default'}>
      <Select onChange={(item) => handleChangeGame(item) } style={{marginBottom: '32px', width: 300}}>
        {games.map((v) => (
          <Select.Option key={v.id} value={v.uuid}>{v.title}</Select.Option>
        ))}
      </Select>
      <Row>
        <Button onClick={() => changeStep('selectDuration')} style={{marginRight: 32}}>
          Back to duration
        </Button>

        {selectedGame && (
          <Button onClick={() => changeStep('selectComputers')}>
            Select computers
          </Button>
        )}
      </Row>

    </SelectGameStyle>
  )
});