import {provide} from "../../../utils/ioc";
import {action, computed, makeAutoObservable} from "mobx";
import {createTicket, CreateTicketModel, CreateTicketResponse, TicketModel} from '../../../api/createTicket';
import {ComputerModel, getComputersList} from "../../../api/getComputersList";
import {GameModel} from "../../../api/getGameInfo";
import {Game, getGamesList} from "../../../api/getGamesList";
import {startGame, StartGameRequest} from "../../../api/startGame";
import {notification} from "antd";
import {restartPC} from "../../../api/restartPC";
import {deleteSuperHot} from "../../../api/deleteSuperHot";
import {startMultipleGames, StartMultipleGamesRequest} from "../../../api/startMultipleGames";

export type SetupStep = 'selectGame' | 'selectPlayers' | 'selectDuration' | 'selectComputers';
@provide.singleton()
export class CoreStore {
  constructor() {
    makeAutoObservable(this)
  }

  idToTicket: Map<string, TicketModel> = new Map();
  idToComputer: Map<string, ComputerModel> = new Map();
  idToGame: Map<string, Game> = new Map();

  @computed
  get games() {
    return Array.from(this.idToGame.values()).sort((a, b) => a.title.localeCompare(b.title));
  }

  @action
  restartPC = (uuid: string) => restartPC(uuid);

  @action
  deleteSuperHot = (uuid: string) => deleteSuperHot(uuid);

  @computed
  get tickets() {
    return Array.from(this.idToTicket.values());
  }

  @computed
  get computers() {
    return Array.from(this.idToComputer.values());
  }

  @action
  fetchGames = async () => {
    let data;
    try {
      data = await getGamesList();
    } catch (e) {
      console.warn('CANT FETCH GAMES', e);
      return;
    }
    this.idToGame.clear();
    //@ts-ignore
    data.data.forEach(game => {
      this.idToGame.set(game.uuid, game);

    });
  }

  @action
  fetchComputers = async () => {
    let data;
    try {
      data = await getComputersList();
    } catch (e) {
      console.warn('CANT FETCH COMPUTERS', e);
      return;
    }
    console.log('fetch computes', data);
    //@ts-ignore
    data.data.map((computer) => {
      this.idToComputer.set(computer.uuid, computer);
    });
  }

  @action
  createTickets = async (players: number, duration: number) => {
    let requests = [];

    for (let i = 0; i < players; i++) {
      requests.push(createTicket({expiresInMinutes: duration}));
    }
    let promises: CreateTicketResponse[] = [];
    try {
      promises = await Promise.all(requests);
    } catch (e) {
      console.warn('CANT CREATE TICKETS', e);
      return;
    }
    return promises.map(item => item.data.payload.uuid);
  };


  @action
  createTicket = async (duration: number) => {
    let data: CreateTicketResponse ;
    try {
      data = await createTicket({
        expiresInMinutes: duration
      }) as any;
    } catch (e) {
      console.log(e);
      return;
    }

    this.idToTicket.set(data.data.payload.uuid, data.data.payload);
    return data.data.payload.uuid
  }

  @action
  startGames = async (computers: string[]) => {
    let ticketsResponse = [];
    try {
      ticketsResponse = await this.createTickets(computers.length, this.duration) as string[];
    } catch (e) {
      notification.error({
        message: 'Can not create tickets',
      })
      return
    }

    let requestData: StartMultipleGamesRequest = [];

    for (let i = 0; i < computers.length; i++) {
      requestData.push({
        gameUuid: this.selectedGame!.uuid,
        compUuid: computers[i],
        ticketUuid: ticketsResponse[i],
        steam_game_id: this.selectedGame!.steam_game_id
      });
    }
    this.startGame(requestData);

  }

  @action
  startGame = async (data: StartMultipleGamesRequest) => {
    let response;
    try {
      response = await startMultipleGames(data);
    } catch (e) {
      console.log(e);
      throw e;
    }

    console.log('start game', response);
  }

  @action
  assignUserToComputer = (v: string) => {
    this.players = 1;
    this.step = 'selectGame';
    this.selectedTicketUuid = v;
  }

  // SETUP WIZARD

  step: SetupStep = 'selectPlayers';
  players: number = 1;
  duration: number = 1;
  selectedGame: Game | null = null;
  selectedComputers: ComputerModel[] = [];
  selectedTicketUuid: string | null = null;

  @action
  setPlayers = (players: number) => {
    this.players = players;
    this.step = 'selectDuration';
  }

  @action
  setDuration = (duration: number) => {
    this.duration = duration;
    this.step = 'selectGame';
  }

  @action
  setGame = (game: Game) => {
    this.selectedGame = game;
    this.step = 'selectComputers';
  }

  @action
  setComputers = (computers: ComputerModel[]) => {
    this.selectedComputers = computers;

  }
  @action
  changeStep = (v: SetupStep) => {
    this.step = v;
  }

  @action
  resetSetup = () => {
    this.step = 'selectPlayers';
    this.players = 1;
    this.duration = 1;
    this.selectedGame = null;
    this.selectedComputers = [];
    this.selectedTicketUuid = null;
  }
}
