import axios, {AxiosRequestConfig} from "axios";

const createApi = (baseUrl: string) => {
  return axios.create({
    baseURL: baseUrl,
    timeout: 100000,
  })
}

export const api = createApi("https://api.jinxvr.com");

api.interceptors.request.use((config: AxiosRequestConfig) => {
  // @ts-ignore
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
  return config;
});
