import {api} from "./api";
import {CreateTicketModel, CreateTicketRequest} from "./createTicket";

export type AddTimeRequest = {
  compUuid: string,
  minutes: number
}

export type AddTimeResponse = {

}

export const addTime = (data: AddTimeRequest) => {
  return api.post<any>("/addTime", data);
}