import {Navigate, Route, Routes} from "react-router-dom";
import {FC} from "react";
import {SetupManager} from "./pages/SetupManager/SetupManager";

export const TicketRouter: FC = () => {
  return (
    <Routes>
      <Route path={''} element={<SetupManager/>}/>
      <Route path={'*'} element={<Navigate to={''}/>}/>
    </Routes>
  )
}