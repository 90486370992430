import {AxiosResponse} from "axios";
import {api} from "./api";

export type StopGameRequest = {
  compUuid: string
}


export type StopGameResponse = AxiosResponse<{

}>

export const stopGame = (data: StopGameRequest) => {
  return api.post<StopGameResponse>("/stopGame", data);
}