import {AxiosResponse} from "axios";
import {api} from "./api";

export type Game = {
  description: string;
  exe_name: string;
  id: number;
  max_player_number: number;
  min_player_number: number;
  steam_game_id: string;
  title: string;
  uuid: string;
  wallpaper: string;
  youtube_url: string;
}

export type GetGamesListResponseDto = AxiosResponse<Game[]>

export const getGamesList = () => {
  return api.get<Game[]>('/gameList');
}