import styled from "styled-components";
import {Card} from "antd";

export const SelectTicketCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  
  & .ant-card-head {
    text-align: center;
  }
  
  & .ant-card-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
  
  & .ant-card-body .ant-btn {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  
  & .ant-row {
    display: flex;
    justify-content: center;
  }
  
  @media(min-width: 600px) {
    & .ant-card-head {
      text-align: left;
    }

    & .ant-card-body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    & .ant-card-body .ant-btn {
      margin-bottom: 0;
      margin-right: 20px;
    }
    
    & .ant-row {
      justify-content: flex-start;
    }
  }
`