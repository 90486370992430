import {api} from "./api";
import {AxiosResponse} from "axios";

export type LoginResponseDto = AxiosResponse<{
  access_token: string;
}>

export const attemptLogin = (username: string, password: string) => {
  return api.post<{
    access_token: string;
  }>("/login", {
    username,
    password,
  });
}