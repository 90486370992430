import {api} from "./api";
import {AxiosResponse} from "axios";

export type ComputerModel = {
  active: boolean;
  game?: any;
  id: number;
  ip: string;
  pc_name: string;
  state: string;
  game_name: string;
  ticket?: any;
  uuid: string;
  time: number;
};

export type GetComputersListResponseDto = AxiosResponse<ComputerModel[]>

export const getComputersList = () => {
  return api.get<ComputerModel[]>("/pcList");
}