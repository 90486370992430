import {FC} from "react";
import {Card, Button} from "antd";
import {useNavigate} from "react-router-dom";
import {MainCard} from "./main.style";

export const Main: FC = () => {
  const navigate = useNavigate();

  return (
    <MainCard title={'Main menu'} size={'default'}>
      <Button onClick={() => navigate('tickets/')} style={{marginRight: '10px'}}>
        Tickets
      </Button>
      <Button onClick={() => navigate('monitoring/')} style={{marginRight: '10px'}}>
        Monitoring
      </Button>
      <Button onClick={() => navigate('games/')} >
        Games
      </Button>
    </MainCard>
  )
}