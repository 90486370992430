import {FC, useEffect, useMemo, useState} from "react";
import {GameCard, GamesListWrapper, PageWrapper, ScrollableArea} from "./style";
import Swiper from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import {Button, Card, Col, Modal, Row, Table} from "antd";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../utils/ioc";
import {useNavigate} from "react-router-dom";
import {CoreStore} from "../../ticket/stores/core.store";
import {Game} from "../../../api/getGamesList";
import {Image} from 'antd';

export const GamesPage: FC = observer(() => {
  const navigate = useNavigate();
  const {games, fetchGames} = useStore(CoreStore);
  const [isModalOpen, toggleModal] = useState(false);
  const [currentGame, setCurrentGame] = useState<Game>({
    title: '',
    youtube_url: '',
    wallpaper: '',
    uuid: '',
    description: '',
    exe_name: '',
    steam_game_id: '',
    max_player_number: 0,
    id: 0,
    min_player_number: 0,
  });

  useEffect(() => {
    fetchGames();
  }, [])

  const handleOnClick = (game: Game) => {
    setCurrentGame(game);
    toggleModal(true);
  }

  const modalClose = () => {
    toggleModal(false);
    document.getElementById('youtube_iframe')!.setAttribute('src', '');
  }

  return (
    <GamesListWrapper title={'Games list'} size={'default'}>
      <Button onClick={() => navigate('/dashboard')} style={{marginBottom: '16px'}}>
        Back to main menu
      </Button>
      <Row >
        <ScrollableArea>
          {games.map((game, index) => (
            <GameCard
              title={game.title}
              size={'default'}
              style={{width: '100%', marginBottom: '16px'}}
              key={game.uuid}
              onClick={() => handleOnClick(game)}
            >
              <Image src={game.wallpaper} style={{height: '300px'}} preview={false}/>
            </GameCard>
          ))}
        </ScrollableArea>

      </Row>
      <Modal
        title={currentGame.title}
        centered
        open={isModalOpen}
        okText={'Close'}
        cancelButtonProps={{style: {display: 'none'}}}
        okType={'ghost'}
        onOk={modalClose}
        onCancel={modalClose}
        width={1000}
      >
        <iframe
          id="youtube_iframe"
          width="100%" height="500"
          src={currentGame.youtube_url}
          allowFullScreen={true}
        >
        </iframe>
      </Modal>
    </GamesListWrapper>
  )
})