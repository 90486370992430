import {provide} from "../../../utils/ioc";
import {action, makeAutoObservable} from "mobx";
import {attemptLogin, LoginResponseDto} from "../../../api/login";
import {getProtected} from "../../../api/protected";

@provide.singleton()
export class AuthStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = true;
  isLoggedIn = false;

  @action
  login = async (username: string, password: string) => {
    let response: LoginResponseDto
    try {
      response = await attemptLogin(username, password);
    } catch (e) {
      this.isLoggedIn = false;
      throw Error('Incorrect pair login/password');
    }
    this.isLoggedIn = true;
    localStorage.setItem('token', response.data.access_token);
  }

  @action
  checkIsLoggedIn = async () => {
    this.isLoading = true;
    try {
      await getProtected();
    } catch (e) {
      this.isLoggedIn = false;
      this.isLoading = false;
      throw Error('Unauthorized');
    }

    this.isLoggedIn = true
    this.isLoading = false;
  }
}