import {AxiosResponse} from "axios";
import {api} from "./api";

type Ticket = {
  computer?: any;
  expiresInMinutes: number;
  id: number;
  startDate: string;
  uuid: string;
}
export type GetTicketsListResponseDto = AxiosResponse<Ticket[]>

export const getTicketsList = () => {
  return api.get<Ticket[]>("/ticketList");
}