import {FC, useEffect} from "react";

export const TrailerPage: FC = () => {

  const openFullscreen = (elem: any) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  useEffect(() => {
    const videoTag = document.getElementById('trailer');

    if (!videoTag) {
      return
    }

    setTimeout(() => {
      // @ts-ignore
      videoTag.play();
      openFullscreen(videoTag);
    }, 3000);
  }, [])

  return (
    <div>
      <video
        src={'https://api.jinxvr.com/static/trailer.mp4'}
        width="100%" height="100%"
        controls
        id="trailer"
        muted={true}
        loop={true}
        style={{
          position: 'fixed',
          top: 0,
          left: 0
      }}
      />
    </div>
  )
}