import {provide} from "../../../utils/ioc";
import {action, computed, makeAutoObservable} from "mobx";
import {ComputerModel, getComputersList, GetComputersListResponseDto} from "../../../api/getComputersList";
import {notification} from "antd";
import {CreateTicketModel, TicketModel} from "../../../api/createTicket";
import {stopGame} from "../../../api/stopGame";
import {getTicketsList, GetTicketsListResponseDto} from "../../../api/getTicketsList";
import {addTime} from "../../../api/addTime";


const REQUEST_INTERVAL_MS = 5000;

@provide.singleton()
export class MonitoringStore {
  constructor() {
    makeAutoObservable(this)
  }

  monitoringInterval = null;

  idToComputers: Map<string, ComputerModel> = new Map();
  idToTickets: Map<string, TicketModel> = new Map();

  @computed
  get tickets(): TicketModel[] {
    return Array.from(this.idToTickets.values());
  }

  @computed
  get computers(): ComputerModel[] {
    return Array.from(this.idToComputers.values());
  }

  @action
  fetchTickets = async () => {
    let response: GetTicketsListResponseDto;
    try {
      response = await getTicketsList();
    } catch (e) {
      console.warn('CANT FETCH TICKETS', e);
      return;
    }
    this.idToTickets.clear();
    response.data.forEach((ticket) => {
      this.idToTickets.set(ticket.uuid, ticket);
    });
  }

  @action
  startMonitoringInterval = () => {
    if (this.monitoringInterval) {
      return;
    }
    this.fetchComputers();
    this.fetchTickets();
    console.log('START MONITOR');
    // @ts-ignore
    this.monitoringInterval = setInterval(() => {
      this.fetchTickets();
      this.fetchComputers();
    }, REQUEST_INTERVAL_MS);
  }


  @action
  stopMonitoringInterval = () => {
    console.log("STOP");
    if (!this.monitoringInterval) {
      return;
    }
    clearInterval(this.monitoringInterval);
  };

  @action
  fetchComputers = async () => {
    console.log('GEEEEEEEEET');
    let response: GetComputersListResponseDto;

    try {
      response = await getComputersList() as any;
    } catch (reason) {
      notification.error({
        message: "Can't fetch computers",
      })
      return;
    }

    console.log('fetch computers', response);
    this.idToComputers.clear();
    response.data.forEach(computer => {
      this.idToComputers.set(computer.id.toString(), computer);
    })
  }

  @action
  addTime = async (computerId: string, minutes: number) => {
    try {
      await addTime({
        compUuid: computerId,
        minutes
      });
    } catch (e) {
      notification.error({
        message: "Can't add time",
      })
      return
    }
    notification.success({
      message: "Can't add time",
    })
  }

  @action
  stopGame = async (uuid: string) => {
    try {
      await stopGame({compUuid: uuid})
    } catch (reason) {
      notification.error({
        message: "Can't stop game",
      })
    }
  }
}