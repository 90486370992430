import {api} from "./api";

export type StartMultipleGamesRequest = {
  compUuid: string;
  gameUuid: string;
  ticketUuid: string;
  steam_game_id: string
}[]

export const startMultipleGames = async (data: StartMultipleGamesRequest) => {
  return api.post("/startMultipleGame", data)
}