import {Route, Routes} from "react-router-dom";
import {FC} from "react";
import {MonitoringPage} from "./pages/MonitoringPage";

export const MonitoringRouter: FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<MonitoringPage/>}/>
    </Routes>
  )
}