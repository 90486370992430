import {FC} from "react";
import {Route, Routes} from "react-router-dom";
import {GamesPage} from "./pages/games.page";

export const GamesRouter: FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<GamesPage/>}/>
    </Routes>
  )
}