import {FC, useEffect, useState} from "react";
import {Button, Card, notification, Row} from "antd";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../utils/ioc";
import {CoreStore} from "../../stores/core.store";
import {SelectTicketCard} from "./select-ticket.style";

const TicketsConfig = [
  10, 15, 30, 60, 120
]

export const SelectTicketDurationPage: FC = observer(() => {
  const navigate = useNavigate();
  const {changeStep, setDuration} = useStore(CoreStore);

  const handleSelectDuration = async (duration: number) => {
    setDuration(duration)
    changeStep('selectGame');
  }

  return (
    <SelectTicketCard title={'Please select ticket duration'} size={'default'}>
      {TicketsConfig.map((v) => (
        <Button key={'minute-' + v} onClick={() => handleSelectDuration(v)}>
          {v} Minutes
        </Button>
      ))}
      <Row style={{width: '100%'}}>
        <Button onClick={() => changeStep('selectPlayers')} style={{marginTop: '16px'}}>
          Back to players
        </Button>
      </Row>

    </SelectTicketCard>
  )
})